.site-form-in-drawer-wrapper {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 100%;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
  border-top: 1px solid #e9e9e9;
}
.mydrawer {
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.mydrawer .am-drawer-sidebar {
  background-color: #fff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.mydrawer .am-drawer-sidebar .am-list {
  width: 300px;
  padding: 0;
}
@media (max-width: 767px) {
  .text-center {
    display: block !important;
    /* 使用 !important 来覆盖任何其他可能隐藏它的样式 */
  }
}
/* .title {
    position: absolute;
    top: 0;
    color: #fff;
    text-align: center;
    line-height: 4.0625rem;
    left: 0;
    padding: 0 0.9375rem;
    width: 10rem;
    height: 4.0625rem;
    font-size: 1.4375rem;
    border-radius: 0 0 0.625rem 0.625rem;
    background-color: rgb(97, 186, 184);
  } */
